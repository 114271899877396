.header img {
  width: 35px;
  display: inline-block;
}

.content {
  height: 100vh;
  padding: 14px;
  background: #f0f2f5 !important;
}

.footer {
  background-color: #fff !important;
  text-align: center;
}

@media screen and (max-width: 600px) {
  .content {
    padding: 10px;
  }
}
