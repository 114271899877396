.filter-laporan-keuangan {
  display: grid;
  max-width: 1700px;
  gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}

.select-option-kode-keuangan {
  min-width: max-content;
  overflow-x: auto;
}

/* .ant-select-item-option-content {
  overflow-x: auto !important;
} */

@keyframes rotateOpen {
  from {
    transform: rotate(45deg);
  }
  to {
    transform: rotate(0deg);
  }
}

@keyframes rotateClose {
  from {
    transform: rotate(45deg);
  }
  to {
    transform: rotate(0deg);
  }
}

.rotateOpen {
  animation: rotateOpen 0.3s forwards;
}

.rotateClose {
  animation: rotateClose 0.3s forwards;
}

.table-header-secondary {
  margin: 0 !important;
  margin-top: 30px !important;
  font-size: 1.2em !important;
  font-weight: 500 !important;
  font-family: "Onest", sans-serif !important;
  text-transform: uppercase !important;
}

.table-recap {
  margin-top: 5px !important;
}

.table-recap-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  border: 1px solid #cccbc9;
  margin-top: 30px;
  overflow-y: auto;
}

.table-recap-box {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  padding: 10px;
  gap: 10px;
}

/* select second table-recap-box */
.table-recap-box:nth-child(2) {
  border-top: 1px solid #cccbc9;
}

.table-recap-item {
  display: grid;
  grid-template-columns: 1fr 1.5fr;
}

.table-recap-item p {
  margin: 0;
  font-weight: 400;
}

.table-recap-master
  .ant-table
  .ant-table-tbody
  .ant-table-expanded-row
  > .ant-table-cell {
  background-color: #4169e1 !important;
  padding: 20px !important;
}

.table-recap-master
  .expanded-row
  .ant-table
  .ant-table-tbody
  .ant-table-expanded-row
  > .ant-table-cell {
  background-color: #ffff !important;
  padding: 20px !important;
}
