.rte-wrapper h1 {
  font-size: 2em; /* sekitar 32px */
}
.rte-wrapper h2 {
  font-size: 1.5em; /* sekitar 24px */
}
.rte-wrapper h3 {
  font-size: 1.17em; /* sekitar 20px */
}

.rte-wrapper .ql-align-center {
  text-align: center;
}

.rte-wrapper .ql-align-right {
  text-align: right;
}

.rte-wrapper blockquote {
  margin-left: 20px;
}

.rte-wrapper ul {
  list-style-type: disc;
  margin-left: 35px;
}

.rte-wrapper ol {
  list-style-type: decimal;
  margin-left: 35px;
}
