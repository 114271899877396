.detail-assets__table {
  width: 100%;
}

.detail-assets__table tr {
  display: flex;
  border-bottom: 1px dotted #eee;
}

.detail-assets__table th,
.detail-assets__table td {
  padding: 8px;
  text-align: left;
}

.detail-assets__table th {
  flex: 1;
  font-size: 1.1em;
  font-weight: 400;
}
.detail-assets__table td {
  flex: 5;
  color: #777;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 1200px) {
  .detail-assets__table th {
    flex: 1;
  }
  .detail-assets__table td {
    flex: 2;
  }
}
