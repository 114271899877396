/* *Download Tagihan Styles */

.main {
  /* display: flex;
  flex-direction: column;
  justify-content: center; */
  /* align-items: center; */
  display: grid;
  place-items: center;
  min-height: 100vh;
  min-width: min-content;
}

.container {
  max-width: 800px;
  min-width: 800px;
  /* padding: 10px; */
}

/* Header ---*/
.header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  gap: 20px;
}

.header .logo {
  width: 50px;
  height: 50px;
}

.header .logo img {
  width: 100%;
}

.header .text {
  text-align: center;
}

.header .text h1 {
  font-size: 18px;
  font-weight: 600;
}

.header .text p {
  font-size: 14px;
  /* line-height: 5px; */
}

.lineHead {
  display: block;
  width: 100%;
  height: 4px;
  background-color: black;
}
/* end header --- */
.body {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  flex-direction: column;
}

.body .title {
  text-align: center;
}
.body .title h1 {
  font-size: 18px;
  font-weight: 600;
  line-height: 1px;
}

.body .title span {
  font-size: 12px;
}

.body .identity {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  gap: 20px;
}

.body .identity h1 {
  font-size: 14px;
}

.body .content {
  display: flex;
  justify-content: space-around;
  /* align-items: center; */
  margin-top: 10px;
  gap: 20px;
  border: 1px solid #bbb;
  padding: 10px 0px;
}

.body .content h2 {
  font-size: 14px;
  font-weight: 600;
  margin-top: 20px;
  text-align: center;
}

.body .note {
  font-size: 12px;
  margin-top: 30px;
  text-align: center;
  line-height: 5px;
}
/* *End Download Tagihan Styles */
