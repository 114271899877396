.detail-billings__table {
   width: 100%;
   margin-bottom: 30px;
 }
 
 .detail-billings__table tr {
   display: flex;
   flex-wrap: wrap;
   border-bottom: 1px dotted #ddd;
 }
 
 .detail-billings__table th,
 .detail-billings__table td {
   padding: 8px;
   text-align: left;
 }
 
 .detail-billings__table th {
   flex: 1;
   font-size: 1.1em;
   font-weight: 400;
 }
 .detail-billings__table td {
   flex: 5;
   color: #777;
   display: flex;
   align-items: center;
   gap: 5px;
   flex-wrap: wrap;
 }
 
 @media screen and (max-width: 1200px) {
   .detail-billings__table th {
     flex: 1;
   }
   .detail-billings__table td {
     flex: 2;
   }
 }
 