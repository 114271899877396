.table {
  width: 100%;
  text-align: center;
}

.table .ant-form-item {
  margin-bottom: 0px;
}

.table tr {
  border-bottom: 1px solid #ededed;
  height: 50px !important;
}
.table tr td {
  font-size: 1.1em;
  text-align: left;
}
.table tr td:first-child {
  width: 30%;
}
.table tr td:last-child {
  width: 70%;
}

.table .ant-row.ant-form-item {
  margin-bottom: 0;
}

.table input,
.table .ant-select-selector,
.table .ant-picker {
  border: none !important;
  font-size: 1em;
}

.transparent-rfid {
  color: transparent;
}
