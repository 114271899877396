.card {
  width: 100%;
  height: 254px;
  background: #1677ff;
  border-radius: 15px;
  /* box-shadow: 1px 5px 60px 0px #0f71fa; */
}

.card .card_border_top {
  width: 60%;
  height: 3%;
  background: #4096ff;
  margin: auto;
  border-radius: 0px 0px 15px 15px;
}

.card h1 {
  font-weight: 400;
  color: white;
  text-align: center;
  display: block;
  padding-top: 10px;
  font-size: 16px;
  padding-bottom: 0;
  margin-bottom: 0;
}

.card span {
  font-weight: 600;
  color: white;
  text-align: center;
  display: block;
  padding-top: 5px;
  font-size: 16px;
}

.card .job {
  font-weight: 400;
  color: white;
  display: block;
  text-align: center;
  padding-top: 3px;
  font-size: 14px;
}

.card .img {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 80px;
  background: #4096ff;
  border-radius: 15px;
  margin: auto;
  margin-top: 25px;
}

.card button {
  padding: 8px 35px;
  display: block;
  margin: auto;
  border-radius: 8px;
  border: none;
  margin-top: 20px;
  /* background: #4096ff; */
  background: #ffff;

  color: black;
  font-weight: 600;
}

.card button:hover {
  /* background: #6ba5eb; */
  background: #dad9d9;
  cursor: pointer;
}
