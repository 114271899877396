/* tab */
.tab-wrapper {
  width: 50%;
}

@media screen and (max-width: 1200px) {
  .tab-wrapper {
    width: 100%;
  }
}
