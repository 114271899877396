.login {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login__wrapper {
  width: 75%;
  height: 90vh;
  display: flex;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.left__content,
.right__content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.left__content {
  flex: 1.2;
  border-radius: 5px 10px 10px 5px;
}

.right__content {
  flex: 2;
}

.content__wrapper {
  width: 60%;
}

.content__wrapper-title {
  font-family: "Ubuntu", sans-serif;
  font-size: 1.9em !important;
  margin-bottom: 40px;
}
.content__wrapper-title:last-of-type {
  font-family: "Ubuntu", sans-serif;
  font-size: 1.7em !important;
  margin-bottom: 20px;
}
p.content__wrapper-description {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 0.9em !important;
  color: rgba(255, 255, 255, 0.75);
  line-height: 23px;
}

.left__content {
  background: #1677ff;
}
.left__content .content__wrapper,
.left__content .content__wrapper-title {
  color: #fff;
}

.right__content {
  background: #fff;
  text-align: center;
}
.right__content .content__wrapper-title::after {
  content: "";
  display: block;
  width: 40px;
  height: 3px;
  border-radius: 2px;
  background-color: #007cef;
  margin: auto;
  margin-top: 7px;
}

.content__wrapper-form label {
  font-size: 1.1em !important;
  font-weight: 600;
}

.ant-form-item.border-only-bottom {
  margin-bottom: 13px !important;
}

.border-only-bottom input,
.border-only-bottom .ant-input-password {
  border: none;
  outline: none;
  border-bottom: 1px solid #ccc;
  border-radius: 0;
  box-shadow: none !important;
}
.border-only-bottom .ant-select-selector {
  border: none !important;
  outline: none !important;
  border-bottom: 1px solid #ccc !important;
  border-radius: 0;
  box-shadow: none !important;
}

.login__wrapper button {
  display: block;
  width: 60% !important;
  height: 40px;
  margin: 20px auto;
  font-size: 1.2em;
  font-weight: 600;
  letter-spacing: 1px;
  /* box-shadow: 0 0 7px rgba(0, 0, 0, 0.3) !important; */
}
.login__wrapper button.ant-btn-block {
  width: 70% !important;
  box-shadow: none !important;
  background: rgba(255, 255, 255, 0.2);
  border: 1px solid #eee;
  color: #fff;
  margin-left: 0;
  margin-top: 40px;
  font-size: 1em;
  text-transform: uppercase;
}

.login__wrapper button.ant-btn-block:hover {
  background: rgba(255, 255, 255, 0.2);
  background: rgba(255, 255, 255, 0.8);
  border-color: #fff;
  color: #444;
}

.content__wrapper-form button:first-of-type {
  margin-top: 30px;
}

.register {
  font-size: 12px;
  margin-top: 20px;
  color: #444;
  display: none;
}

.register span {
  font-weight: bold;
  color: #1677ff;
  cursor: pointer;
}

@media screen and (max-width: 1400px) {
  .login__wrapper {
    width: 80%;
  }

  .content__wrapper {
    width: 70%;
  }

  .left__content .content__wrapper-title {
    font-size: 1.6em !important;
  }

  .content__wrapper-form button {
    width: 60% !important;
  }
}

@media screen and (max-width: 1300px) {
  .content__wrapper-title {
    font-size: 2.2em;
  }

  .content__wrapper-title::after {
    width: 20%;
  }

  .content__wrapper-description,
  .content__wrapper-form label {
    font-size: 1em !important;
  }

  .content__wrapper-form button {
    font-weight: 400;
    letter-spacing: 0;
  }
}

@media screen and (max-width: 1100px) {
  .login__wrapper {
    width: 90%;
  }

  .content__wrapper {
    width: 80%;
  }

  .content__wrapper-title {
    font-size: 2em;
  }

  .content__wrapper-title {
    margin-bottom: 25px;
  }

  .content__wrapper-description,
  .content__wrapper-form label {
    font-size: 0.95em !important;
  }
}

@media screen and (max-width: 900px) {
  .content__wrapper-title::after {
    width: 15%;
  }

  .content__wrapper-form button {
    width: 70% !important;
  }

  .login__wrapper button.ant-btn-block {
    width: 100% !important;
  }
}

@media screen and (max-width: 768px) {
  .login__wrapper {
    width: 80%;
  }

  .left__content {
    display: none;
  }

  .content__wrapper {
    width: 70%;
  }

  .content__wrapper-form button {
    font-size: 1.1em;
  }

  .register {
    display: block;
  }
}

@media screen and (max-width: 468px) {
  .login__wrapper {
    width: 90%;
  }

  .content__wrapper {
    width: 80%;
  }

  .content__wrapper-title {
    font-size: 1.9em;
  }

  .content__wrapper-form button {
    width: 100% !important;
  }
}

@media screen and (max-width: 400px) {
  .content__wrapper-title::after {
    width: 22%;
  }
}
