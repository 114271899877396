.form-wrapper {
  width: 50%;
  margin-bottom: 70px;
}
.form-container {
  display: flex;
  justify-content: center;
}

/* master pendaftaran for candidate */
.ant-table-body {
  min-height: 80px;
}
/* create pendaftaran */
.btn-act {
  float: right;
  width: 120px;
}

/* for candidate jsx-- */
.table-pendaftaran-candidate th.ant-table-cell {
  background-color: #1677ff !important;
  color: white !important;
}

.img_candidate {
  width: 50px;
  border-radius: 50%;
  height: 50px;
}
/* create daftar ulang */
.space-upload {
  margin-top: 20px;
}

/* untuk upload */
/* .ant-upload-list-item.ant-upload-list-item-undefined {
  position: absolute !important;
  border: 1px solid #4096ff !important;
  width: 200px;
  height: 20px !important;
  font-size: 12px !important;
} */

.anticon.anticon-file {
  /* display: none; */
  width: 10px;
}

@media screen and (max-width: 1200px) {
  .form-wrapper {
    width: 100%;
  }
}
