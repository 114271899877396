.login-ppdb-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  /* background-color: #1677ff; */
}
.login-ppdb-card h3 {
  text-align: center;
  font-size: 17px;
  margin: 0;
}
.login-ppdb-card h3.school {
  /* color: #4096ff; */
}
.login-ppdb-card h3.ppdb {
  font-size: 20px;
  font-weight: bold;
  color: #4096ff;
}

.title-login {
  margin-top: -15px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.title-login img {
  width: 30px;
  text-align: center;
  margin: 10px;
}
.container-login-ppdb {
  /* background-color: red; */
}
.login-ppdb-card .btn-login {
  height: 40px;
  font-size: 18px;
  width: 100% !important;

  /* background: rgb(50, 109, 191);
  background: linear-gradient(
    90deg,
    rgba(50, 109, 191, 1) 0%,
    rgba(64, 64, 184, 1) 50%,
    rgba(81, 38, 255, 1) 100%
  ); */
}

.login-ppdb-card {
  position: relative;
  background-color: white;
  width: 500px;
  height: 500px;
  box-shadow: 0px 3px 5px 2px rgba(0, 0, 0, 0.202);
  border-radius: 10px;
  padding: 60px;
}

.google-account {
  /* margin-top: 15px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}
.register-login {
  float: left;
  margin: 5px 0px 15px;
  position: absolute;
}
.register-login span {
  color: #1677ff;
  cursor: pointer;
}

.footer-ppdb-login {
  margin-top: 20px;
  /* color: white; */
  font-size: 13px;
  display: flex;
  align-items: left;
  /* justify-content: center; */
  flex-direction: column;
  gap: 5px;
}
.footer-ppdb-login img {
  width: 20px;
  border: 1px solid white;
  /* margin-top: -10px; */
  margin-left: 52px;
}

@media screen and (max-width: 500px) {
  .login-ppdb-card {
    width: 100%;
  }
}
