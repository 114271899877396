.filter-search {
  display: grid;
  gap: 10px;
  margin-bottom: 10px;
  margin-top: 20px;

  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}

@media (max-width: 768px) {
  .filter-search {
    grid-template-columns: 1fr;
    gap: 10px;
  }
}

.search-input-billings {
  border-radius: 0px;
  border: none;
  border: 1px solid #eee;
  /* border-bottom: 1px solid #eee; */
  color: #aaa;
  background: #f0f3f8;
}

.search-input-billings .ant-input-affix-wrapper-focused,
.search-input-billings .ant-input-affix-wrapper:hover {
  box-shadow: none;
}

.search-input-billings .ant-input {
  background: #f0f3f8;
}

.search-input-billings .ant-input::placeholder {
  color: #aaa;
  text-transform: lowercase;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

@media (max-width: 567px) {
  .order-1 {
    order: 2 !important;
  }

  .order-2 {
    order: 1 !important;
  }
}
