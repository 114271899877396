.header-container {
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.header-container .user-profile {
  height: 40px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 7px;
  background: #f5f6f9;
  border-radius: 10px;
  padding: 0 20px;
  cursor: pointer;
  color: #666;
  font-size: 1.1em;
}

@media screen and (max-width: 1200px) {
  .header-container .user-profile {
    font-size: 1em;
  }
}
span.ant-menu-title-content {
  font-size: 15px;
}
