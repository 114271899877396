.wrapper-input {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  align-items: center;
  margin-bottom: 20px;
}
.item-input-wrapper {
  min-width: 280px;
  margin: 0;
}

.input-inline {
  display: flex;
  width: 45%;
  gap: 20px;
  margin-bottom: -20px;
}
@media screen and (max-width: 700px) {
  .item-input-wrapper {
    min-width: 100%;
  }
  .input-inline {
    width: 100%;
  }
}
