.container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.text {
  font-size: 40px;
}

@media screen and (max-width: 600px) {
  .text {
    font-size: 30px;
  }
}