.mreport-list td.ant-table-cell {
  padding: 1px !important;
}

.report-monthly-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 400px));
  gap: 20px;
  margin-top: 32px;
}

@media screen and (max-width: 500px) {
  .report-monthly-list {
    grid-template-columns: 1fr;
  }
}

.report-monthly-card {
  max-width: 400px;
  display: flex;
  gap: 20px;
  align-items: center;
  border: 1px solid #ccc;
  padding: 20px 24px;
  border-radius: 10px;
  cursor: pointer;
  transition: box-shadow 0.3s;
}

.report-monthly-card:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.report-monthly-text {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.report-monthly-title {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 0;
}

.report-monthly-subtitle {
  font-size: 14px;
  color: #8c8c8c;
  margin-bottom: 0;
}

.monthly-report-card-menu {
  display: flex;
  gap: 18px;
  align-items: center;
}

.monthly-report-card-menu p {
  margin-bottom: 0 !important;
}

.monthly-filter {
  display: grid;
  max-width: 600px;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 10px;
}

@media screen and (max-width: 500px) {
  .monthly-filter {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
  }
}

@media screen and (max-width: 388px) {
  .monthly-filter {
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }

  .item-search {
    grid-column: 1 / 3;
  }
}
