.loan-batch-wrapper .ant-row.ant-form-item {
  margin-bottom: 0;
}

.loan-batch-wrapper .form .ant-table {
  border-radius: 0 !important;
  box-shadow: none !important;
}

.loan-batch-wrapper .ant-form-item {
  margin-bottom: 0px;
}

.loan-batch-wrapper .form .ant-table table {
  display: flex;
}
.loan-batch-wrapper .form .ant-table table thead {
  width: 30%;
}

.loan-batch-wrapper .form .ant-table th.ant-table-cell {
  font-size: 1em;
}

.loan-batch-wrapper .form .ant-table table tbody {
  width: 70%;
}
.loan-batch-wrapper .form .ant-table tr {
  display: flex;
  flex-direction: column;
}

.loan-batch-wrapper .form th.ant-table-cell {
  background-color: #fff !important;
  color: #000 !important;
  padding: 8px !important;
  max-width: 100%;
}
.loan-batch-wrapper .form th.ant-table-cell::before {
  display: none;
}

.form td.ant-table-cell {
  max-width: 100%;
}
.form.form-edit td.ant-table-cell {
  padding: 3px !important;
  border-radius: 0px !important;
  border: none !important;
}

.form td.ant-table-cell input,
.form td.ant-table-cell .ant-picker,
.form td.ant-table-cell .ant-select-selector {
  border-color: #ededed !important;
}

.table-pink {
  background: #ffecef !important;
}

.loan-batch-wrapper .ant-table tr:nth-child(even) {
  background-color: #fff;
}

.loan-batch-wrapper .ant-table-row:hover td {
  background: none !important;
}

@media screen and (min-width: 1600px) {
  .form.form-edit td.ant-table-cell {
    padding: 3px !important;
  }
}
