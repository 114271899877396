.report-triwulan-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 400px));
  gap: 20px;
  margin-top: 32px;
}

@media screen and (max-width: 500px) {
  .report-triwulan-list {
    grid-template-columns: 1fr;
  }
}
