/* p {
  font-size: 16px;
  margin-bottom: 0 !important;
} */

.modal-alokasi-title {
  font-size: 24px;
  font-weight: 600;
  color: #000000;
  margin-bottom: 20px !important;
}

.modal-secondary-heading {
  font-size: 20px;
  font-weight: 600;
  color: #000000;
}

.modal-paragraph {
  font-size: 16px;
  font-weight: 400;
  color: #000000;
}

.modal-amount-container {
  margin-top: 20px;
}

.modal-amount-box {
  display: flex;
  justify-content: space-between;
}

.box-top-border {
  margin-top: 40px;
  position: relative;
}

.box-top-border::before {
  content: "";
  position: absolute;
  top: -10px;
  left: 0;
  width: 100%;
  border-top: 1px solid gray;
}
