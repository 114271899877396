.firstContent {
  border: 1px solid #e6e5e5;
  padding: 15px 10px;
  border-radius: 5px;
  font-weight: 400;
}

.firstContent p {
  font-size: 14px;
  color: #787878;
}

.firstContent h1 {
  font-size: 20px;
  font-weight: bold;
}

.firstContent h2 {
  font-size: 14px;
  margin-top: 20px;
}

.firstContent .shortcutWrapper {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

/* --- */

.info {
  border: 1px solid #e6e5e5;
  padding: 15px 10px;
  border-radius: 5px;
  font-weight: 400;
}

.info h1 {
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: bold;
}
.info p {
  font-size: 14px;
  color: #787878;
}

.kehadiran {
  border: 1px solid #e6e5e5;
  padding: 15px 10px;
  border-radius: 5px;
  font-weight: 400;
}

.kehadiran h1 {
  font-size: 20px;
  font-weight: bold;
}
