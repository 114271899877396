.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

@media (max-width: 567px) {
  .order-1 {
    order: 2 !important;
  }

  .order-2 {
    order: 1 !important;
  }
}
