.marks-read-text {
  cursor: pointer;
  font-size: 14px;
  color: #1677ff;
  text-decoration: none;
  transition: color 0.3s ease-in-out;
}

.marks-read-text:hover {
  color: #91caff;
}

.marks-read-text-disabled {
  cursor: not-allowed;
  font-size: 14px;
  color: #ccc;
  text-decoration: none;
}
