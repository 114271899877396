.alokasi-detail {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4px;
  max-width: 500px;
  margin-bottom: 12px;
}

.alokasi-detail p {
  margin: 0;
  font-size: 14px;
  font-weight: 500;
}
