.select-option-foundation {
  min-width: max-content;
  overflow-x: auto;
}

.filter-table {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  max-width: 800px;
  gap: 10px;
}

@media screen and (max-width: 350px) {
  .filter-table {
    gap: 0;
  }
}

/* .form-filter {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-width: 100%;
  gap: 10px;
} */
