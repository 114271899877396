.table-prosem-header {
  width: 100%;
}

.table-prosem-header tr {
  display: flex;
  padding: 2px 0;
}

.table-prosem-header tr th {
  flex: 1;
  text-align: left;
  font-size: 1.1em;
}

.table-prosem-header tr td {
  flex: 2.5;
  text-align: left;
  font-size: 1.1em;
}

@media screen and (max-width: 1000px) {
  .table-header.prosem-detail {
    flex-direction: column;
    align-items: flex-end !important;
  }
  .table-prosem-header tr th {
    flex: 1;
    font-size: 1em;
  }

  .table-prosem-header tr td {
    flex: 1;
    font-size: 1em;
  }
}
