/* * profile --- */
@import url("https://fonts.googleapis.com/css?family=Montserrat");

.candidate-profile h3 {
  font-weight: bold;
  margin: 10px 0;
  color: #4169e1;
}

.candidate-profile h6 {
  margin: 5px 0;
  text-transform: uppercase;
}

.candidate-profile p {
  font-size: 14px;
  line-height: 21px;
}

.candidate-profile .card-container {
  /* background-color: #231e39; */
  /* background-color: #4169e1; */
  border-radius: 5px;
  box-shadow: 0px 10px 20px -10px rgba(0, 0, 0, 0.75);
  color: #b3b8cd;
  padding-top: 30px;
  position: relative;
  width: 350px;
  max-width: 100%;
  text-align: center;
}

.candidate-profile .card-container .pro {
  color: #231e39;
  background-color: #febb0b;
  border-radius: 3px;
  font-size: 14px;
  font-weight: bold;
  padding: 3px 7px;
  position: absolute;
  top: 30px;
  left: 30px;
}

.candidate-profile .card-container .round {
  border: 1px solid #1c4de3;
  border-radius: 50%;
  padding: 7px;
  width: 100px;
  height: 100px;
  margin-top: 30px;
}

.candidate-profile .skills {
  background-color: #4169e1;
  text-align: left;
  padding: 15px;
  margin-top: 30px;
}

.profile-ppdb-wrapper {
  display: flex;
  /* justify-content: center; */
  flex-direction: column;
  align-items: center;
  height: 100vh;
}
