@media print {
  @page {
    /* size: a4; */
    size: latter;
  }

  /* .break-print {
    page-break-before: always;
  } */
  .parentPrint {
    height: 100% !important;
  }

  .float-btn {
    display: none !important;
  }
}

/* container  */
.raport-page-container {
  background-color: red;
  min-width: 1000px;
}

/* end container */

/* 1. HALAMAN COVER ---- */
.cover-raport {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
}

.cover-raport .title h1 {
  font-size: 24px;
  text-align: center;
  font-weight: bold;
  padding: 0;
  margin: 0;
}
.cover-raport .title {
  margin-bottom: 20px;
}

.table-cover tr,
td {
  padding: 5px;
}
/* END HALAMAN COVER */

/* 2. HALAMAN DATA DIRI ---- */
.data-diri .title h1 {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 20px;
}

.paraf-data-diri {
  margin-top: 50px;
  display: flex;
  gap: 50px;
}
.paraf-data-diri .pas-foto {
  width: 3cm;
  height: 3.5cm;
  background-color: white;
  border: 0.8px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
}
.paraf-data-diri .paraf p {
  margin: 0;
  padding: 0;
}
.paraf-data-diri .paraf span {
  display: block;
  margin-top: 50px;
  font-weight: bold;
}
/* END HALAMAN DATA DIRI */

/* 3. HALAMAN UMUM / RAPORT ---- */

/* ---presensi kehadiran */
.table-grub-raport {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

/* END HALAMAN UMUM / RAPORT */

/** 4. general style */

.border-page {
  border: 1px solid #323639;
  margin-bottom: 10px;
}

/* ---comp div tabs */
.container-tabs {
  background-color: #323639;
  height: 100vh;
  overflow: auto;
  display: flex;
  justify-content: center;
}
.papper-raport {
  background-color: white;
  width: 8.5in;
  /* min-height: 14in; */
  min-height: 14in;
}
/* ---comp container */
.container-raport-siswa {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
}

/* .raport-border tr,
td,
th {
  border: 1px solid black;
} */

.raport-border tr {
  border: 1px solid black;
}

.raport-border td {
  border: 1px solid black;
}

.raport-border th {
  border: 1px solid black;
  padding-left: 5px;
  padding-right: 5px;
}

.th-space th {
  padding-left: 5px;
  padding-right: 5px;
}

.container-raport-siswa td {
  padding: 8px !important;
}

/* ---comp identitas */
.identitas-raport-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  margin-top: 30px;
}

.identitas-raport-container .col-idn {
  display: flex;
  gap: 10px;
}
.identitas-raport-container .col-idn p {
  margin: 0;
}

/* ---comp paraf */
.paraf-container {
  display: flex;
  margin-top: 30px;
  justify-content: space-between;
  width: 100%;
}
.paraf-container p {
  margin: 0;
  padding: 0;
}
.paraf-container .wali-kelas {
  margin-top: 15px;
}
.paraf-container span {
  margin-top: 60px;
  display: block;
}
/** End general style */

/* tabs style */
.tabs-raport {
  background-color: #212426;
}

@media screen and (max-width: 768px) {
  .ant-tabs-nav {
    display: none !important;
  }
  .layout-raport-page {
    transform: scale(0.5);
    transform-origin: 0 0;
  }
}
@media screen and (max-width: 478px) {
  .container-tabs {
    justify-content: start;
  }
}

/* END tabs style */

.exmp {
  font-size: 12px !important;
}
