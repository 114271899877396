.detail-student__table {
  width: 100%;
}

.detail-student__table tr {
  display: flex;
  border-bottom: 1px dotted #eee;
}

.detail-student__table th,
.detail-student__table td {
  padding: 8px;
  text-align: left;
}

.detail-student__table th {
  flex: 1;
  font-size: 1.1em;
  font-weight: 400;
}
.detail-student__table td {
  flex: 5;
  color: #777;
  display: flex;
  align-items: center;
}

.title-detail-student {
  color: #4169e1;
  font-weight: bold;
}

.navigasi-tab-detail {
  margin-top: 50px;
  display: flex;
  justify-content: flex-end;
  margin-right: 80px;
}

@media screen and (max-width: 1200px) {
  .detail-student__table th {
    flex: 1;
  }
  .detail-student__table td {
    flex: 2;
  }
  .navigasi-tab-detail {
    margin-right: 0;
  }
}
