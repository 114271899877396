.filterList {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

@media (max-width: 768px) {
  .filterList {
    grid-template-columns: repeat(1, 1fr);
    gap: 0px;
  }
}
