.form-input-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 16px;
}

@media (max-width: 1000px) {
  .form-input-container {
    grid-template-columns: 1fr;
  }
}
