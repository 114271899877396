.recap-presence__table {
  width: 100%;
}

.recap-presence__table tr {
  display: flex;
}

.recap-presence__table th,
.recap-presence__table td {
  padding: 6px;
  text-align: left;
}

.recap-presence__table th {
  flex: 2;
  font-size: 1.1em;
  font-weight: 400;
}
.recap-presence__table td {
  flex: 4;
  color: #777;
  display: flex;
  align-items: center;
}

.ant-collapse-content-box {
  padding: 0px !important;
}

.modal-title p {
  margin: 0px;
  margin-top: -5px;
  margin-bottom: 3px;
  font-size: 1em;
  text-transform: uppercase;
}

.modal-title table {
  width: 100%;
}

.modal-title table td {
  font-weight: 400;
  font-size: 0.95em;
  text-transform: capitalize;
}

.ant-collapse-header-text div {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 800px) {
  .recap-presence__table th {
    flex: 1;
    font-size: 1em;
  }
  .recap-presence__table td {
    flex: 1;
    font-size: 0.9em;
  }
}
