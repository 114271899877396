.chart-container-wrap {
  overflow-x: "auto";
  height: 400px;
  overflow-y: auto;
}

.chart-container-wrap1 {
  width: 100%;
  overflow-y: auto;
}

@media (max-width: 700px) {
  .chart-container-wrap {
    width: 260%;
  }
  .chart-container-wrap1 {
    width: 200%;
  }
}
