.container {
  display: flex;
  justify-content: center;
  backdrop-filter: blur(5px);
}
.container::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 112px;
  background-color: #3b88e7;
  background-image: url("https://images.unsplash.com/photo-1553532434-5ab5b6b84993?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1674&q=80");
  background-attachment: fixed;
  z-index: -2;
}
.container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 120px;
  background-color: #3b88e7af;
  backdrop-filter: blur(5px);

  z-index: -1;
}

.containerForm {
  border-radius: 16px;

  background-color: white;

  margin: 10px;
  padding: 50px;
  width: 750px;
  box-shadow: 0px 0.7px 1.9px rgba(0, 0, 0, 0.01),
    0.1px 1.7px 4.4px rgba(0, 0, 0, 0.015),
    0.2px 3.1px 7.9px rgba(0, 0, 0, 0.018),
    0.3px 5.1px 13.2px rgba(0, 0, 0, 0.022),
    0.5px 8.5px 21.7px rgba(0, 0, 0, 0.025),
    0.9px 14.8px 37.9px rgba(0, 0, 0, 0.03), 2px 32px 82px rgba(0, 0, 0, 0.04);
}

@media screen and (max-width: 700px) {
  .containerForm {
    padding: 20px;
    margin: 0px;
    border-radius: 0px;
  }
}
