.card {
  border: 1px solid #e6e5e5;
  padding: 15px 10px;
  border-radius: 5px;
  font-weight: 400;
}

@media screen and (max-width: 768px) {
  .card {
    width: 100%;
    padding: 10px 5px;
  }
}

.greetingHeader {
  font-size: 20px;
  font-weight: bold;
  color: #000000;
  line-height: 1;
  margin-bottom: 10px;
}

.greetingDate {
  font-size: 14px;
  color: #787878;
}

.flexItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.headerDescription {
  font-size: 14px;
  color: #787878;
}

.badge span {
  font-size: 14px !important;
  color: #ffffff;
}

.menuBoxShortcut {
  margin-top: 20px;
}

.headerSecondary {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
}

.headerThird {
  font-size: 14px;
  margin-top: 20px;
}

.totalEmployee {
  color: #787878;
}

.menuList {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 10px;
}

.recapList {
  display: grid;
  margin-top: 20px;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: 20px;
}

@media screen and (max-width: 400px) {
  .recapList {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }
}

.filterKepegawaian {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 10px;
}

@media screen and (max-width: 500px) {
  .filterKepegawaian {
    grid-template-columns: repeat(1, 1fr);
  }
}

.badgeBox {
  display: flex;
  flex-direction: column;
  font-size: 16px !important;
  gap: 10px;
  margin-top: 30px;
}
