@import url("https://fonts.googleapis.com/css2?family=Onest:wght@200;300;400;500&display=swap");

.App {
  font-size: 16px;
}

/* login start */
.input-email-login,
.input-password-login {
  background-color: #fff !important;
}

.input-submit-login {
  color: #efefef !important;
  height: 50px !important;
  margin: 10px 0;
  transition: 0.3s;
  border: none !important;
}

.login-input {
  border: none !important;
  background-color: #fff !important;
  padding: 10px !important;
  box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.1);
}
/* login end */

/* dashboard start */
.table-header,
.presence-table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
  margin-bottom: 10px;
}

.table-header > div {
  display: flex;
  flex-wrap: nowrap;
}

.table-header button,
.presence-table-header button {
  border-radius: 3px;
}

.presence-table-header .includes-export {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
}

.presence-table-header .includes-export button:first-child,
.presence-table-header .includes-export button:nth-child(2) {
  margin-right: 5px;
}

.excel-btn {
  width: 32px;
  cursor: pointer;
}

.table-header h1,
.presence-table-header h1,
.student-table-header h1 {
  margin-bottom: 0px;
  font-size: 1.2em;
  font-weight: 500;
  font-family: "Onest", sans-serif;
  text-transform: uppercase;
}

.search-wrapper .ant-input-affix-wrapper {
  padding: 10px;
  padding-left: 12px;
  border-radius: 0px;
  margin-bottom: 20px;
  border: none;
  border: 1px solid transparent;
  color: #aaa;
  background: #f0f3f8;
}

.search-wrapper .ant-input-affix-wrapper-focused,
.search-wrapper .ant-input-affix-wrapper:hover {
  box-shadow: none;
  border: 1px solid #ddd !important;
}

.search-wrapper .ant-input-affix-wrapper svg {
  margin-right: 7px;
}

.search-wrapper .ant-input {
  background: #f0f3f8;
}

.search-wrapper .ant-input::placeholder {
  color: #aaa;
  text-transform: lowercase;
}

.search-wrapper.filter-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 10px;
}
.search-wrapper.filter-wrapper > div label {
  display: block;
  margin-bottom: 4px;
}
.filter-column {
  display: flex;
  gap: 10px;
}
@media screen and (max-width: 900px) {
  .filter-column {
    flex-wrap: wrap;
  }
}
/* dashboard end */

/* universal start */
.table-employee-wrapper {
  display: flex;
}

.table1,
.table2 {
  flex: 1;
}

.detail-employee__table {
  width: 100%;
}

.detail-employee__table tr {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid #ededed;
}

.detail-employee__table th,
.detail-employee__table td {
  padding: 8px;
  text-align: left;
}

.detail-employee__table th {
  flex: 1;
  font-size: 1.1em;
  font-weight: 400;
}
.detail-employee__table td {
  flex: 3;
  color: #777;
  display: flex;
  align-items: center;
  gap: 5px;
  flex-wrap: wrap;
}

.double-form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
}

.form-control {
  margin-bottom: 15px;
}

.form-control label {
  font-weight: bold;
}

.form-control input,
form button,
.form-control select {
  width: 100%;
  border: 1px solid #d9d9d9;
  padding: 5px;
  border-radius: 2px;
  color: #666;
}
.form-control input,
.form-control select {
  border-color: #40a9ff;
  border-right-width: 1px;
}
.form-control input:focus,
.form-control select:focus {
  border-color: #40a9ff;
  box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
  border-right-width: 1px;
  outline: 0;
}

form button {
  background-color: #1890ff;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
}

form button:hover {
  background-color: #4da6f9;
}

.delete-title {
  font-size: 25px;
  font-weight: 500;
  text-align: center;
  text-transform: capitalize;
}

.login-btn .ant-form-item-control-input-content {
  margin-top: 100px;
  display: flex;
  gap: 10px;
}

.button-green {
  background-color: #18978f !important;
  color: #fff !important;
  border: none !important;
}
.button-green:disabled {
  background-color: #f5f5f5 !important;
  border: 1px solid #dcdcdc !important;
  color: #bbb !important;
}

/* ant-breadcrumb start */
.ant-breadcrumb {
  margin-left: 22px !important;
  font-size: 1.2em;
}
.ant-breadcrumb li:last-child a {
  color: rgba(0, 0, 0, 0.45) !important;
}
.ant-breadcrumb li:last-child a:hover {
  color: black !important;
}

.title-case {
  text-transform: capitalize !important;
}
/* ant-breadcrumb end */

.table-image {
  width: 100px;
  max-height: 50px;
  object-fit: cover;
  object-position: center;
}

/* universal end */

/* calendar */
.ant-picker-calendar table.ant-picker-content {
  border: none;
}

.ant-picker-calendar .ant-picker-body th {
  border: 1px solid #ddd;
  padding: 5px !important;
  text-align: center;
}

.ant-picker-calendar .ant-picker-body td {
  border: 1px solid #ddd;
}

.ant-picker-calendar .ant-picker-calendar-date {
  border: none !important;
  margin: 0 !important;
}

@media screen and (max-width: 1600px) {
  .ant-breadcrumb {
    display: none;
  }

  .table-header button,
  .presence-table-header button {
    font-size: 0.94em;
  }

  /* .ant-breadcrumb {
    margin-left: 22px !important;
    font-size: 1.1em;
  } */

  .search-wrapper .ant-input-affix-wrapper,
  .search-wrapper div.ant-picker {
    padding: 7px;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 1400px) {
  .card-component {
    font-size: 1em;
  }
  .card-component div:first-child h3 {
    font-size: 1.1em;
  }

  .card-component div:last-child {
    width: 55px;
    height: 55px;
  }

  .detail-employee__table th {
    flex: 1;
  }
  .detail-employee__table td {
    flex: 2;
  }
}

@media screen and (max-width: 1300px) {
  .card-component div:last-child {
    width: 55px;
    height: 55px;
  }
}

@media screen and (max-width: 1100px) {
  .table-employee-wrapper {
    flex-direction: column;
  }
  .detail-employee__table th,
  .detail-employee__table td {
    font-size: 0.9em;
  }
}

@media screen and (max-width: 1000px) {
  /* .ant-breadcrumb {
    display: none;
  } */

  .table-header h1 {
    font-size: 1em;
  }
}

@media screen and (max-width: 800px) {
  .ant-breadcrumb {
    margin-left: 15px !important;
    font-size: 1em;
  }

  .table-header button,
  .presence-table-header button {
    font-size: 0.9em;
  }

  .btn-orange {
    margin-bottom: 5px;
  }

  .presence-table-header {
    display: grid;
    grid-template-columns: 1fr;
    gap: 5px;
  }

  .presence-table-header .includes-export button:first-child,
  .presence-table-header .includes-export button:nth-child(2) {
    margin-right: 0px;
  }

  .search-wrapper.filter-wrapper {
    flex-direction: column;
  }
  .search-wrapper.filter-wrapper > div {
    width: 100%;
  }

  .search-wrapper .ant-input::placeholder {
    color: #aaa;
    text-transform: lowercase;
    font-size: 0.92em;
  }
}

@media screen and (max-width: 600px) {
  .double-form {
    grid-template-columns: 1fr;
  }
}
