.report_container {
  min-width: 1000px;
  overflow: auto;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 0.9em;
}

.report_container > div:first-child {
  position: fixed;
  top: 20px;
  right: 100px;
  display: flex;
  justify-content: flex-end;
}

.report_wrapper {
  width: 1000px;
  max-width: 1000px;
  margin: auto;
  padding-top: 20px;
}

.report-logo {
  max-width: 900px;
  margin: auto;
  font-size: 0.9em;
  display: flex;
  align-items: center;
}

.report-logo div:first-child {
  width: 30%;
  padding-top: 5px;
  box-sizing: border-box;
}

.report-logo div:first-child img {
  width: 80%;
}

.report-logo div:last-child {
  text-align: left;
}

.report-logo h1 {
  text-transform: uppercase;
  font-size: 1.5em;
  margin-bottom: 0px;
}

.report-logo p,
.report-logo span {
  font-size: 0.5em;
  margin-bottom: 0px;
}

.report_wrapper table {
  width: 100%;
  max-width: 900px;
  margin: 10px auto;
  border: 1px solid black;
  margin-bottom: 15px;
}

.report_wrapper table td,
.report_wrapper table th {
  border: 1px solid black;
  padding: 2px;
}

.report_wrapper table:nth-child(3) tbody tr td:first-child {
  width: 5%;
}

.report_wrapper:not(.triwulan) table:nth-child(3) tbody tr td.title {
  text-transform: uppercase;
  font-weight: 600;
  text-align: left;
}

.report_wrapper:not(.triwulan) table:nth-child(3) tbody tr td:nth-child(3) {
  width: 20%;
}

.report_wrapper:not(.triwulan) table:nth-child(3) tbody tr td:nth-child(4) {
  width: 30%;
}

.report_wrapper table:nth-child(2) tbody tr td,
.report_wrapper table:nth-last-child(2) tbody tr td {
  border: none;
  border-bottom: 1px solid black;
}

.report_wrapper table:nth-child(4) tbody tr td:first-child {
  width: 50%;
  text-align: left;
}

.report_wrapper table:nth-child(3) tbody td:nth-child(odd) {
  text-align: center;
}

.report_wrapper th.center,
.report_wrapper td.center {
  text-align: center;
}

.report_wrapper > div:last-child {
  width: 100%;
  max-width: 900px;
  margin: 10px auto;
  display: flex;
}

.report_wrapper > div:last-child > div:first-child img {
  width: 300px;
}

.report_wrapper > div:last-child > div:last-child img {
  width: 150px;
}

.signature-wrapper {
  display: flex;
  justify-content: flex-end;
}
#signature-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* triwulan start */

.report_wrapper.triwulan table:nth-child(3) tbody tr td:nth-child(2) {
  width: 45%;
}

.report_wrapper.triwulan table:nth-child(3) tbody tr td:nth-child(2n + 3) {
  background-color: #ccc;
}

.report_wrapper.triwulan table:nth-child(3) tbody tr td:nth-child(n + 3) {
  width: 50px;
}

.report_wrapper.triwulan table:nth-child(3) tbody td {
  text-align: center;
}

.report_wrapper.triwulan
  table:nth-child(3)
  tbody
  tr:not(:nth-child(1))
  td:nth-child(2) {
  text-align: left;
}

.report_wrapper.triwulan table:nth-child(3) tbody tr:nth-child(1) td {
  text-align: left;
  text-transform: uppercase;
  font-weight: bold;
}

.report_wrapper.triwulan table:nth-child(4) tbody tr td:nth-child(1) {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  width: 10%;
}
.report_wrapper.triwulan table:nth-child(4) tbody tr td:nth-child(2),
.report_wrapper.triwulan table:nth-child(4) tbody tr td:nth-child(3) {
  width: 20%;
  text-align: center;
}

.report_wrapper.triwulan table:nth-child(4) tbody tr:nth-child(1) td {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
}

.report_wrapper.triwulan
  table:nth-child(4)
  tbody
  tr:nth-child(3)
  td:nth-child(4),
.report_wrapper.triwulan
  table:nth-child(4)
  tbody
  tr:nth-child(4)
  td:nth-child(4) {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
}

.report_wrapper.triwulan
  table:nth-child(4)
  tbody
  tr:first-child
  td:nth-child(4) {
  width: 25%;
}

.report_wrapper.triwulan table:nth-last-child(3) tr td:first-child {
  width: 50%;
  text-align: left;
  border-right: none;
}
.report_wrapper.triwulan table:nth-last-child(3) tr td:last-child {
  border-left: none;
}

.report_wrapper.triwulan table:nth-last-child(2) tr td {
  width: 50%;
  text-align: center;
  border: 1px solid black;
}

/* triwulan end */

@media screen and (max-width: 1600px) {
  .report_container > div:first-child {
    right: 40px;
  }
}

@media screen and (max-width: 1200px) {
  .report_container > div:first-child {
    right: 20px;
  }
}
