.form-item-kehadiran {
  width: 50%;
}

.date-input {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
}

.button-container {
  z-index: 1000;
  width: 50%;
  margin-top: 30px;
  margin-inline: auto;
  position: sticky;
  bottom: 2vh;
}

.tag-list {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}
.card-info {
  padding-top: 22px;
  padding-bottom: 22px;
}

@media (max-width: 1200px) {
  .form-item-kehadiran {
    width: 100%;
  }

  .date-input {
    grid-template-columns: 1fr;
  }

  .button-container {
    width: 100%;
  }
}
