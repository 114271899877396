.filter-pendapatan {
  display: grid;
  max-width: 1200px;
  gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
}

.select-option-kode-keuangan {
  min-width: max-content;
  overflow-x: auto;
}

/* .ant-select-item-option-content {
  overflow-x: auto !important;
} */
