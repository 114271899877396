.chart-wrapper {
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  gap: 20px;
}

.chart-wrapper1 {
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 20px;
}

.chart-wrapper2 {
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 20px;
}

.chart-item {
  background: #fff;
  padding: 15px;
  padding-top: 60px;
  border: 1px solid #e3e6f0;
  border: 1px solid #e6e9f5;
  border-radius: 0.35rem;
  box-sizing: border-box;
  box-shadow: 0 20px 27px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
  position: relative;
}
.chart-item:hover {
  box-shadow: 0px 20px 35px rgba(0, 0, 0, 0.08);
  transition: 0.3s;
}

.chart-item h3 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0.75rem 1.25rem;
  background-color: #f8f9fc;
  border-bottom: 1px solid #e3e6f0;
  color: #4e73df;
  font-weight: 600;
}

@media screen and (max-width: 800px) {
  .chart-wrapper {
    grid-template-columns: 1fr;
  }
  .chart-wrapper1 {
    grid-template-columns: 1fr;
  }
  .chart-wrapper2 {
    grid-template-columns: 1fr;
    gap: 20px;
  }
}
