.btn {
  cursor: pointer;
}

/* master ppdb */

/* Student Come Pages --- */
.welcome-ppdb {
  gap: 10px;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
}
.welcome-ppdb img {
  width: 150px;
}
.welcome-ppdb h1 {
  color: #4169e1;
  font-size: 20px;
  font-weight: bold;
}

.progress-status-wrapper {
  display: flex;
  justify-content: center;
}
.progress-status {
  background-color: #4169e120;
  width: 100%;
  border-radius: 10px;
  padding: 30px;
}

.btn-help {
  width: 40px !important;
  height: 40px !important;
  box-shadow: 0 1px 5px #acacac;
}

@media screen and (max-width: 575px) {
  .ant-tour-content {
    width: 50%;
  }
}

.guide-view {
  text-align: center;
  /* background-color: #f7f7f7; */
  background-color: #e7ecfb;
  padding: 20px;
  margin-top: 20px;
  /* box-shadow: 0 1px 5px #acacac30; */
}

/* Count PPDB PAGES card count--- */
.count-ppdb {
  margin-top: 50px;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.card-count-ppdb {
  background-color: red;
  height: 100px;
  width: 40px;
  display: flex;
  flex: 1 0 140px;
  border-radius: 4px;
  flex-direction: column;
  padding: 20px;
  box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.202);
}
.card-count-ppdb h1 {
  font-size: 25px;
  font-weight: bold;
  margin: 0;
  padding: 0;
}

.card-1 {
  background-color: #d0eeeb;
}
.card-2 {
  background-color: #dfddfc;
}
.card-3 {
  background-color: #f6dade;
}
