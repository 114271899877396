.loan-asset-wrapper .ant-table tr:nth-child(even) {
  background-color: #fff;
}

.loan-asset-wrapper .table-pink {
  background: #ffecef !important;
}

.loan-asset-wrapper .ant-table-row:hover td {
  background: none !important;
}

.transparent-rfid {
  color: transparent;
}

.asset-filter {
  display: grid;
  max-width: 700px;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 10px;
}

@media screen and (max-width: 500px) {
  .asset-filter {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
  }
}

@media screen and (max-width: 388px) {
  .asset-filter {
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }

  .item-search {
    grid-column: 1 / 3;
  }
}
