.presence__table {
  width: 100%;
}

.presence__table tr {
  display: flex;
  border-bottom: 1px dotted #eee;
}

.presence__table th,
.presence__table td {
  padding: 6px;
  text-align: left;
}

.presence__table th {
  flex: 1;
  font-size: 1.1em;
  font-weight: 400;
}
.presence__table td {
  flex: 3;
  color: #777;
  display: flex;
  align-items: center;
}

.ant-form-item-control .placeholder-color-black input::placeholder {
  color: black;
}

.presence-filter {
  display: grid;
  max-width: 900px;
  grid-template-areas: "first first second second third third";
  gap: 10px;
}

.presence-filter > div:first-child {
  grid-area: first;
}
.presence-filter > div:nth-child(2) {
  grid-area: second;
}
.presence-filter > div:last-child {
  grid-area: third;
}

@media screen and (max-width: 576px) {
  .presence-filter {
    margin-top: 20px;
    grid-template-areas:
      "first first first first first first"
      "second second second third third third";
  }
}
