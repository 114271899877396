.select-option-foundation {
   min-width: max-content;
   overflow-x: auto;
 }
 
 .filter-table {
   display: grid;
   grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
   max-width: 800px;
   gap: 10px;
 }
 
 @media screen and (max-width: 350px) {
   .filter-table {
     gap: 0;
   }
 }
 .weekend-date .ant-picker-cell-inner {
  color: red;
}