.profile__table__wrapper {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  overflow: auto;
}

@media screen and (max-width: 1100px) {
  .profile__table__wrapper {
    grid-template-columns: 1fr;
  }
}
